
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import { ChromePicker, ColorResult } from 'react-color';



import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';
import useProductService, { useBasicFilterProduct, useBasicFilterProductSearch } from './services/Product';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IFormationBillingType, IPackagingProduct, IProduct, IRentalResource, IServiceTask, ProductType, defaultProduct } from './models/Product';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
      Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING, 
      Enum_RENTAL_PERIODICITY, 
      Enum_CONSUMPTION_OPTION_CLASS} from 'features/configuration/models/Enumeration';

import {ISharing} from 'features/setup/models/Sharing';
import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IArticleStock } from 'features/configuration/models/Delivery';
import { useBasicFilterResource } from 'features/production/services/Resource';
import { IResource } from 'features/production/models/Resource';
import BasicImportForm from 'components/ui/BasicImportForm';

export const ProductForm: FC<IProduct> = (props: IProduct = defaultProduct) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const {getArticlePerStores } = useDeliveryService();

  const { createProduct, createBasicImportProduct ,updateProduct, getProductsSearch, getProductsSearchCount } = useProductService();

  const {capitalizeFirstLetter} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterProduct = useBasicFilterProduct( 0,
    (event: React.MouseEvent<unknown>, row: IProduct) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );


  const [openSharingFilter, setOpenSharingFilter] = useState(false);
  const basicFilterSharing = useBasicFilterSharing( 
      (event: React.MouseEvent<unknown>, row: ISharing) => {
          const {id, name, description} = row;

          setValue('sharingId', id);
          setValue('sharingName', name);
                           
          setOpenSharingFilter(false);
      }
  );
  

  const [openProductFilter, setOpenProductFilter] = useState(false);
  const basicFilterPackagingProduct = useBasicFilterProduct( 1,
      (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, name, description, type} = row;

          //if( ['packaging', 'formation', 'maintenance', 'contract'].includes(type) ) return;

          if( type !== 'service' && type !== 'article' ) return;
          
          // setValue('enumerationId', id);
          // setValue('enumerationName', name);

          (refAppendPackagingProducts.current??emptyFunc)({id: 0, type, packagingId: _id, serviceId: id, articleId: id, 
            serviceBeneficiaryPointExpression: '',  productName: name , personServiceCode: '' });
                           
          setOpenProductFilter(false);
      }
  );

  const [openResourceFilter, setOpenResourceFilter] = useState(false);
  const basicFilterResource = useBasicFilterResource( 
      (event: React.MouseEvent<unknown>, row: IResource) => {
          const {id, description,permanentResourceId: resourceTypeId, capacity ,permanentResourceName: resourceTypeName} = row;
        
          const findResource = getValues().rentalResources.find(x => x.resourceId === id);
          if(!isFalsy(findResource)) return;
          
          (refAppendRentalResources.current??emptyFunc)({id: 0, rentalId: _id, resourceId: id, isCapacityFixed: true,
            defaultCapacity: capacity, resourceName: description });                          
        setOpenResourceFilter(false);
      }
  );

  const methods = useForm<IProduct>({defaultValues: {...defaultProduct, 
    type: applicationSetup.hasService? 'service': 
            applicationSetup.hasArticle ? 'article' : applicationSetup.hasPackaging? 'packaging':
              applicationSetup.hasFormation? 'formation' : applicationSetup.hasMaintenance ? 'maintenance' : 
                applicationSetup.hasContract ?'contract' : 'fee' }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchIsTaxable = watch('isTaxable');
  const watchIsDefaultTaxFormula = watch('isDefaultTaxFormula');

  const watchBase64Picture = watch('base64Picture');

  const watchSharingId = watch('sharingId');    
  const watchProductType = watch('type');    
  const watchCode = watch('code');    

  const watchName = watch('name');    
  const watchDescription = watch('description');    

  const [openProductSearchFilter, setOpenProductSearchFilter] = useState(false);
  const basicFilterProductSearch = useBasicFilterProductSearch( 
      () => {
        const { name, description} = getValues();

        return {name, description};
      },      
      (event: React.MouseEvent<unknown>, row: IProduct) => {
        const {id, name, description} = row;
        
        _setId(row.id);
        setValue('id', id);
        setValue('name', name);
        setValue('description', description); 

        setOpenProductSearchFilter(false);
      }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<string>('#000');

  // const watchParentProductId = watch('parentProductId');

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IProduct>,Error,IProduct>(
      _id>0?updateProduct:createProduct, {   
        onSuccess: (data: IResult<IProduct>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Product')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Product',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

      const {isLoading: isLoadingBasicImport, isError: isErrorBasicImport, error: errorBasicImport,mutate: mutateBasicImport } = useMutation<IResult<boolean>,Error,IProduct[]>(
        createBasicImportProduct, {   
          onSuccess: (data: IResult<boolean>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                     
            setIsSaveLoading(false);
            //_setId(data.data.id);
            //setCurrentEntityIdForAction(data.data.id);
            
            //queryClient.invalidateQueries(['Product',data.data.id]);
          },
          onError: (err: Error) => {          
            enqueueSnackbar( error?.message, { variant: 'error',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setIsSaveLoading(false);
          }
        });

    const {data: _data, refetch} = useQuery<IProduct>(['Product', _id], () => retrieveEntity('Product',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const {data: enumItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', 'Product'], () => 
          getEnumerationItemsByEnumerationCodes( [Enum_LINE_OF_BUSINESS, 
              Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, Enum_ARTICLE_WRAPPING,
              Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE, 
              Enum_RENTAL_PERIODICITY, Enum_CONSUMPTION_OPTION_CLASS ] ));

      const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

      const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
        
        setValueTabCoverage(newValue);       
      };

    
    const handleProductType = ( event: React.MouseEvent<HTMLElement>, newProductType: ProductType ) => {

      if(newProductType === null) return;
      setValue('type', newProductType);

    };

      const [openEntityExpression, setOpenEntityExpression] = useState(false);
      const [currentExpression, setCurrentExpression] = useState(''); 
      const [currentEntityName, setCurrentEntityName] = useState(''); 
      const [currentReturnType, setCurrentReturnType] = useState(''); 

      type ExpressionType = 'price' | 'tax' | 'max-discount' | 'max-loaded' | 'price-unity' | 'customer-point' | 'service-point' | 'packaging-service-point';
      const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('price');

      const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
          entityName: string, returnType: string, expression: string) => {

        setCurrentExpressionType(expressionType);
        setCurrentEntityName(entityName);
        setCurrentReturnType(returnType);
        setCurrentExpression(expression);
        setOpenEntityExpression(true);
      }

      const handleClickOkExpression = async () => {

        const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
       
        if(returnType !== currentReturnType) {
          enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                return;
        }
      
        
        if( ['price', 'tax', 'max-discount', 'max-loaded' ,'price-unity', 'customer-point', 'service-point'].includes(currentExpressionType)  ) {

          const priceExpression = currentExpressionType === 'price' ? currentExpression: getValues().priceExpression;
          const taxExpression = currentExpressionType === 'tax' ? currentExpression: getValues().taxExpression;
          const maxDiscountExpression = currentExpressionType === 'max-discount' ? currentExpression: getValues().maxDiscountExpression;
          const maxLoadedExpression = currentExpressionType === 'max-loaded' ? currentExpression: getValues().maxLoadedExpression;
          const priceExpressionUnity = currentExpressionType === 'price-unity' ? currentExpression: getValues().priceExpressionUnity;

          const customerPointExpression = currentExpressionType === 'customer-point' ? currentExpression: getValues().customerPointExpression;
          const serviceBeneficiaryPointExpression = currentExpressionType === 'service-point' ? currentExpression: getValues().serviceBeneficiaryPointExpression;

          setValue('priceExpression', priceExpression);
          setValue('taxExpression', taxExpression);
          setValue('maxDiscountExpression', maxDiscountExpression);
          setValue('maxLoadedExpression', maxLoadedExpression);
          setValue('priceExpressionUnity', priceExpressionUnity);

          setValue('customerPointExpression', customerPointExpression);
          setValue('serviceBeneficiaryPointExpression', serviceBeneficiaryPointExpression);
        } else if( ['packaging-service-point'].includes(currentExpressionType) ) {

          const packagingService = getValues().packagingProducts.at(packagingProductIndex);
          if(isFalsy(packagingService)) return;

          (refUpdatePackagingProduct.current??emptyFunc)(packagingProductIndex,{...packagingService, 
              serviceBeneficiaryPointExpression: currentExpression });
        }

        setOpenEntityExpression(false);
      }

      const handleClickOpenSharing = (event: any) => {        
        setOpenSharingFilter(true);
      }

      const handleClickRemoveSharing = (event: any) => {
        setValue('sharingId', 0);
        setValue('sharingName', '');     
      }
          
      const getServiceTaskCode = (row: IServiceTask, cellId: keyof IServiceTask, 
        opts: {value: string, name: string}[]) => {        
        
          return getAsOptions(refEnumItems.current ?? [],Enum_SERVICE_TASK);
      }
  
      const cellEditableServiceTask = (row: IServiceTask, cellId: keyof IServiceTask) => {
        return true;
      }
  
      const [headServiceTaskCells, setHeadServiceTaskCells]  = useState<HeadCell<IServiceTask>[]>([      
        //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
        {id:'taskCode', label : t('Task'),  display: true, type: 'string', width: 70, isEditable: cellEditableServiceTask,
          getOptions: getServiceTaskCode   },
        {id:'part', label : `${t('Part')}(%)`,  display: true, type: 'numeric', width: 30, 
            isEditable: cellEditableServiceTask, decimalScale: 4 },       
      ]);
  
      const refAppendServiceTasks = useRef<(value: Partial<FieldArray<IProduct>> | Partial<FieldArray<IProduct>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateServiceTask = useRef<(index: number,value: Partial<FieldArray<IProduct>> ) => void>(null);
      const refRemoveServiceTask = useRef<(index: number ) => void>(null);
        
      const handleAddServiceTasks = (event: any) => {
        (refAppendServiceTasks.current??emptyFunc)({id: 0, serviceId: _id, taskCode: '', part: 0});
      }
  
      const serviceTaskRowActionIcon = ( tarificationMatrix: IServiceTask) : ActionIconTableRow<IProduct,IServiceTask> => {
    
        const res: ActionIconTableRow<IProduct,IServiceTask> = {
          toolTip: 'remove',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IServiceTask) => {
            
             (refRemoveServiceTask.current??emptyFunc)(index);            
          }
        }
        return res;
    }


    const [packagingProductIndex, setPackagingProductIndex] = useState<number>(-1);
    const getInputAdornmentServicePointExpression = (row: IPackagingProduct, cellId: keyof IPackagingProduct)  => ({
 
      toolTip: 'Details',
      icon: DeveloperModeIcon,
      iconDisable: (row.type === 'article'),
      onClickIcon: (event: any, index: number, row: IPackagingProduct ) => {    
        
        setPackagingProductIndex(index);
        handleClickOpenExpression(event, 'packaging-service-point', 'BillingPackagingService', 'numeric',row.serviceBeneficiaryPointExpression)
      }  
    })

    const getPersonService = (row: IPackagingProduct, cellId: keyof IPackagingProduct, 
      opts: {value: string, name: string}[]) => {        
      
        return getAsOptions(refEnumItems.current ?? [],Enum_PERSON_SERVICE);
    }

    const cellEditablePackagingProduct = (row: IPackagingProduct, cellId: keyof IPackagingProduct) => {

      if(row.type === 'article' && cellId === 'personServiceCode') return false;

      return true;
    }

    const [headPackagingProductCells, setHeadPackagingProductCells]  = useState<HeadCell<IPackagingProduct>[]>([]);
    useEffect(() => {
      setHeadPackagingProductCells([      
        //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
        {id:'productName', label : t('Product'),  display: true, type: 'string', width: 50,
          getInputAdornment: getInputAdornmentServicePointExpression },
        {id:'personServiceCode', label : t('Description'),  display: true, type: 'string', width: 30, 
          isEditable: cellEditablePackagingProduct, getOptions: getPersonService },
                
        {id:'part', label : `${t('Part')}(%)`,  display: true, type: 'numeric', width: 20, 
            isEditable: cellEditablePackagingProduct, decimalScale: 4 },        
      ]);
    }, [t,i18n]);
    

    const refAppendPackagingProducts = useRef<(value: Partial<FieldArray<IProduct>> | Partial<FieldArray<IProduct>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdatePackagingProduct = useRef<(index: number,value: Partial<FieldArray<IProduct>> ) => void>(null);
    const refRemovePackagingProduct = useRef<(index: number ) => void>(null);
      
    const handleAddPackagingProducts = (event: any) => {
      setOpenProductFilter(true);      
    }

    const packagingProductRowActionIcon = ( tarificationMatrix: IPackagingProduct) : ActionIconTableRow<IProduct,IPackagingProduct> => {
  
      const res: ActionIconTableRow<IProduct,IPackagingProduct> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IPackagingProduct) => {
          
           (refRemovePackagingProduct.current??emptyFunc)(index);            
        }
      }
      return res;
  }


  const getFormationBillingTypeCode = (row: IFormationBillingType, cellId: keyof IFormationBillingType, 
    opts: {value: string, name: string}[]) => {        
    
      return getAsOptions(refEnumItems.current ?? [],Enum_FORMATION_BILLING_TYPE);
  }

  const cellEditableFormationBillingType = (row: IFormationBillingType, cellId: keyof IFormationBillingType) => {
    return true;
  }

  const [headFormationBillingTypeCells, setHeadFormationBillingTypeCells]  = useState<HeadCell<IFormationBillingType>[]>([]);
  useEffect(() => {
    setHeadFormationBillingTypeCells([      
      //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
      {id:'billingTypeCode', label : t('Type'),  display: true, type: 'string', width: 50, isEditable: cellEditableFormationBillingType,
        getOptions: getFormationBillingTypeCode   },
      {id:'dueDate', label : t('Due date'),  display: true, type: 'date', width: 30, isEditable: cellEditableFormationBillingType,},
      {id:'amountPart', label : `${t('Part')}(%)`,  display: true, type: 'numeric', width: 20, 
          isEditable: cellEditableFormationBillingType, decimalScale: 4 },       
    ]);
  }, [t,i18n]);
  

  const refAppendFormationBillingTypes = useRef<(value: Partial<FieldArray<IProduct>> | Partial<FieldArray<IProduct>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateFormationBillingType = useRef<(index: number,value: Partial<FieldArray<IProduct>> ) => void>(null);
  const refRemoveFormationBillingType = useRef<(index: number ) => void>(null);
    
  const handleAddFormationBillingTypes = (event: any) => {
    (refAppendFormationBillingTypes.current??emptyFunc)({id: 0, formationId: _id, billingTypeCode: '', dueDate: new Date(), amountPart: 0, billingTypeName: ''});
  }

  const formationBillingTypeRowActionIcon = ( formationBillingType: IFormationBillingType) : ActionIconTableRow<IProduct,IFormationBillingType> => {

    const res: ActionIconTableRow<IProduct,IFormationBillingType> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IFormationBillingType) => {
        
         (refRemoveFormationBillingType.current??emptyFunc)(index);            
      }
    }
    return res;
}

const cellEditableRentalResource = (row: IRentalResource, cellId: keyof IRentalResource) => {
  return true;
}

const [headRentalResourceCells, setHeadRentalResourceCells]  = useState<HeadCell<IRentalResource>[]>([]);
useEffect(() => {
  setHeadRentalResourceCells([      
    //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
    {id:'resourceName', label : t('Resource'),  display: true, type: 'string', width: 50   },
    {id:'isCapacityFixed', label : t('Fixed capacity'),  display: true, type: 'boolean', width: 25, isEditable: cellEditableRentalResource,},
    {id:'defaultCapacity', label : `${t('Default capacity')}`,  display: true, type: 'numeric', width: 25, 
        isEditable: cellEditableRentalResource, decimalScale: 2 },       
  ]);
}, [t,i18n]);


const refAppendRentalResources = useRef<(value: Partial<FieldArray<IProduct>> | Partial<FieldArray<IProduct>>[], options?: FieldArrayMethodProps) => void>(null);
const refUpdateRentalResource = useRef<(index: number,value: Partial<FieldArray<IProduct>> ) => void>(null);
const refRemoveRentalResource = useRef<(index: number ) => void>(null);
  
const handleAddRentalResources = (event: any) => {
  setOpenResourceFilter(true);
}

const rentalResourceRowActionIcon = ( rentalResource: IRentalResource) : ActionIconTableRow<IProduct,IRentalResource> => {

  const res: ActionIconTableRow<IProduct,IRentalResource> = {
    toolTip: 'remove',
    icon: RemoveCircleIcon,
    hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
    isActionExecuting: true,
    onRowClickIcon: (event : any,index: number, row: IRentalResource) => {
      
       (refRemoveRentalResource.current??emptyFunc)(index);            
    }
  }
  return res;
}

function openFileDialog() {
  (document as any).getElementById("file-upload").click();
}

const setFile = (_event: any) => {
  let f = _event.target.files![0];

  const fileSizeInKB = f.size / 1024;
  // Check if the file size is within your limit (e.g., 200 KB)
  if (fileSizeInKB > 200) {
    alert(t('File size should be less than 200 KB'));
    return;
  }

  var reader = new FileReader();

  reader.onload = function () {

      var binaryString = reader.result as string;
  
      const base64String = binaryString
                                  .replace('data:', '')
                                  .replace(/^.+,/, '');

      setValue("base64Picture", base64String);
      setValue("fileName", f.name);
    };

    reader.onerror = function () {
      console.log("File load failed");
    };    
    reader.readAsDataURL(f);    
};

const debouncedNameChange = useRef(
  debounce( async () => {      
    const { id, name, description} = getValues();

    if(id>0) return;

    if( (isFalsy(name) || name.trim() === '') && (isFalsy(description) || description.trim() === '' )) return;

    const count = await getProductsSearchCount(name, description);

    if(!isFalsy(refProductSnackbarId.current)) closeSnackbar(refProductSnackbarId.current!);
    
    if(count > 0) 
      refProductSnackbarId.current = enqueueSnackbar( `${t('Product')} --> ${t('You have to check if product exists before save, verify it in this list of')} : ${count} ${t('_product')}(s)`, { variant: 'warning',
            anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
            action: xActionProductSearch() } );             

  }, 2500)
).current;

const refProductSnackbarId = useRef<SnackbarKey>();

const xActionProductSearch = (): SnackbarAction => (snackbarId: SnackbarKey) => (
  <>        
      <Button onClick={() => { 
                           
              setOpenProductSearchFilter(true);
              closeSnackbar(snackbarId); }}>   
          <GrSearch size={24} />
      </Button>
      <Button onClick={() => { closeSnackbar(snackbarId) }}>
          <GrClose size={24} />
      </Button>
  </>
)

  useEffect( () => {     
          
    debouncedNameChange();      
    }, [watchName, watchDescription, debouncedNameChange]);

    const refEnumItems = useRef<IEnumerationItem[]>([]);    
    useEffect( () => {   
        refEnumItems.current = (enumItems || []);
      
    }, [enumItems])

    const [articleStocks, setArticleStocks] = useState<IArticleStock[]>([]);
    useEffect( () => {
      
      async function _() {   
        if(_data && _data.type === 'article' && _data.id > 0) {         
          const stocks = await getArticlePerStores( 0, _id);
          setArticleStocks(stocks);
        }
      }
      _();  
    }, [_data] );

      useEffect( () => {        
        setCurrentFormNameAtom(t('Product'));
        setCurrentBasicTextFilterProps(basicFilterProduct);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Product')} - # ${_id} # -`: t('Product') );
            if(_id > 0)
              retrieveData('Product',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultProduct, 
          type: applicationSetup.hasService? 'service': 
                  applicationSetup.hasArticle ? 'article' : applicationSetup.hasPackaging? 'packaging':
                    applicationSetup.hasFormation? 'formation' : applicationSetup.hasMaintenance ? 'maintenance' : 
                    applicationSetup.hasContract ? 'contract' : 'fee' });    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Product', _id)) {
          setIsSaveLoading(false);
             return;
        }          
  
        const data = getValues(); 

        if( isFalsy(data.lineOfBusinessCode) || data.lineOfBusinessCode.trim() === '') {
          enqueueSnackbar( t('Line of business is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
        
        if(data.name.trim() === '' || data.description.trim() === '') {
            enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

        const taskCodes = data.serviceTasks.map(x => x.taskCode);
        if( Array.from(new Set(taskCodes)).length !== data.serviceTasks.length) {
          enqueueSnackbar( t('Tasks are not well defined'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
    
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Product', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

    const [productsBasicImport, setProductsBasicImport] = useState<IProduct[]>([]);
    const [openBasicImportForm, setOpenBasicImportForm] = useState<boolean>(false);
    const basicImportData = async (event: MouseEvent<HTMLButtonElement>) => {          
      setOpenBasicImportForm(true); 
    }

    const handleBasicImportSave = (event: any) => {

      if(productsBasicImport.length === 0) {
        enqueueSnackbar( t('There is no data to import'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      if(!checkEntitySaveAuthorization('Product', _id)) {
        enqueueSnackbar( t('You can not perform this action'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      if(productsBasicImport.some(p => isFalsy(p.name) || isFalsy(p.description) ) ) {
        enqueueSnackbar( t('There are some product without name or description'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      if(productsBasicImport.some(p => isFalsy(p.lineOfBusinessCode) ) ) {
        enqueueSnackbar( t('There are some product without line of business'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      const badLineOfBusiness = productsBasicImport.filter( p => !refEnumItems.current.some( e => 
            e.enumerationCode === Enum_LINE_OF_BUSINESS && e.code === p.lineOfBusinessCode  ) );

      if(badLineOfBusiness.length !== 0 ) {
        enqueueSnackbar( `${t('There are some product with bad Line of business')} : ${badLineOfBusiness[0].name}` , { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      mutateBasicImport(productsBasicImport);
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup value={watchProductType} exclusive key={watchProductType} size="small" onChange={handleProductType} aria-label="text alignment" fullWidth >
                                {applicationSetup.hasService && <ToggleButton value="service" aria-label="centered">
                                  {t('Service')}
                                </ToggleButton> }
                                {applicationSetup.hasFee && <ToggleButton value="fee" aria-label="centered">
                                  {t('Fee')}
                                </ToggleButton> }
                                {applicationSetup.hasArticle && <ToggleButton value="article" aria-label="centered">
                                  {t('Article')}
                                </ToggleButton> }
                                {applicationSetup.hasPackaging && <ToggleButton value="packaging" aria-label="centered">
                                  {t('Packaging')}
                                </ToggleButton> }         
                                {applicationSetup.hasFormation && <ToggleButton value="formation" aria-label="centered">
                                  {t('Formation')}
                                </ToggleButton> }               
                                {applicationSetup.hasMaintenance && <ToggleButton value="maintenance" aria-label="centered">
                                  {t('Maintenance')}
                                </ToggleButton> }
                                {applicationSetup.hasContract && <ToggleButton value="contract" aria-label="centered">
                                  {t('Contract')}
                                </ToggleButton> }
                                {applicationSetup.hasRental && <ToggleButton value="rental" aria-label="centered">
                                  {t('Rental')}
                                </ToggleButton> }
                                {applicationSetup.hasConsumption && <ToggleButton value="consumption" aria-label="centered">
                                  {t('Consumption')}
                                </ToggleButton> }
                              </ToggleButtonGroup>                                                             
                            </Box> 
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnBasicImport' onClick={basicImportData} sx={ {display:'none'}}  />
                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="name" label={t('Name')} {...register('name')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none',} } } autoFocus  /> 
                                   
                                { openProductSearchFilter && <FormDialog open={openProductSearchFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Product')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenProductSearchFilter(false);}} onOk={()=> {setOpenProductSearchFilter(false);}}  >
                                      <BasicTextFilterForm<IProduct> {...basicFilterProductSearch } />
                              </FormDialog> }                                                  
                            </Box>   
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(40% - 8px)'}} id="name" label={t('Code')} {...register('code')}
                                  helperText={`${watchCode?watchCode.length:0} / 20`}
                                  inputProps={ { autoComplete: 'new-password', maxLength: 20 ,style: {textTransform: 'none'} } } autoFocus  />  
                              <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                  label={`${t('Active ?')}`}
                                  control={
                                  <Controller
                                      name='isActive'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                              <Controller name='lineOfBusinessCode' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="lineOfBusinessCode"
                                        label={t('Line of business')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_LINE_OF_BUSINESS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box>
                                                       
                                                    
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} /> 
                              
                                                             
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >                              
                                
                              <TextField sx={{width:'calc(45% - 8px)'}} id="sharingName" label={`${t('Sharing')}`} 
                                  {...register('sharingName')}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">   
                                        { (isFalsy(watchSharingId) ||  watchSharingId<=0) ? 
                                          <IconButton color="primary" onClick={handleClickOpenSharing}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton> :
                                          <IconButton color="primary" onClick={handleClickRemoveSharing}>
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        }                                                                                             
                                    </InputAdornment>
                                  ) 
                                }}
                              />
                              {watchProductType === 'service' && <Controller                                
                                render={({ field }) => {
                                  return (
                                    <NumberFormat      
                                      //decimalScale={2}
                                      label={'Duration in minutes'} sx={{width:'calc(20% - 8px)'}}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                   
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      {...field}
                                      customInput={TextFieldRight}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`serviceDurationInMinute`}
                                //name={`${fieldsName}.${idx}.${cell.id}`}
                                control={control}
                              /> }
                              {watchProductType === 'service' && <Controller                                
                                render={({ field }) => {
                                  return (
                                    <NumberFormat      
                                      //decimalScale={2}
                                      label={t('Duration in day before renewal')} sx={{width:'calc(35% - 8px)'}}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                   
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      {...field}
                                      customInput={TextFieldRight}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`durationBeforeServiceRenewalInDay`}
                                //name={`${fieldsName}.${idx}.${cell.id}`}
                                control={control}
                              /> }

                              {watchProductType === 'packaging' && <Controller                                
                                render={({ field }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={2}
                                      label={`${t('Cumulative duration rate')}(%)`} sx={{width:'calc(25% - 8px)'}}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                   
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      {...field}
                                      customInput={TextFieldRight}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`cumulativeDurationRate`}
                                //name={`${fieldsName}.${idx}.${cell.id}`}
                                control={control}
                              /> }
                              { watchProductType === 'article' && <Controller name='filterOption' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="filterOption"
                                        label={t('Option')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_ARTICLE_OPTION_CLASS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> }
                              { watchProductType === 'article' && <Controller name='articleWrapping' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="articleWrapping"
                                        label={t('Wrapping')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_ARTICLE_WRAPPING ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> }
                              { watchProductType === 'maintenance' && <Controller name='maintenanceCategoryClass' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="maintenanceCategoryClass"
                                        label={t('Category')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_MAINTENANCE_CATEGORY_CLASS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> }
                                { watchProductType === 'contract' && <Controller name='contractScopeClass' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="contractScopeClass"
                                        label={t('Scope')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_CONTRACT_SCOPE_CLASS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> }
                                { watchProductType === 'consumption' && <Controller name='consumptionOptionClass' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="consumptionOptionClass"
                                        label={t('Option')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_CONSUMPTION_OPTION_CLASS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> }
                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="priceExpression" label={`${t('Expression')} - ${t('Price')}`} 
                                multiline rows={2}
                                  {...register('priceExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) =>
                                            handleClickOpenExpression(event, 'price', `Billing${capitalizeFirstLetter(watchProductType)}`,
                                                        'numeric',getValues().priceExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              />                                                                                                    
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                  label={`${t('Taxable ?')}`}
                                  
                                  control={
                                  <Controller
                                      name='isTaxable'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                              {watchIsTaxable && <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                  label={`${t('Default tax formula ?')} : net*tx/100`}
                                  
                                  control={
                                  <Controller
                                      name='isDefaultTaxFormula'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox disabled {...props} checked={value} onChange={onChange} />}                        
                              />} />  }                                                                                                  
                            </Box> 
                            {!watchIsDefaultTaxFormula && <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="taxExpression" label={`${t('Expression')} - ${t('Tax')}`} 
                                multiline rows={2}
                                  {...register('taxExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) =>
                                            handleClickOpenExpression(event, 'tax', `Billing${capitalizeFirstLetter(watchProductType)}`,
                                                        'numeric',getValues().taxExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              />                                                                                                    
                            </Box> }
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="maxDiscountExpression" label={`${t('Expression')} - ${t('Max. discount')}`} 
                                multiline rows={2}
                                  {...register('maxDiscountExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) =>
                                            handleClickOpenExpression(event, 'max-discount',`Billing${capitalizeFirstLetter(watchProductType)}`, 
                                              'numeric',getValues().maxDiscountExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              />                                                                                                    
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="maxLoadedExpression" label={`${t('Expression')} - ${t('Max. loaded')}`} 
                                multiline rows={2}
                                  {...register('maxLoadedExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) =>
                                            handleClickOpenExpression(event, 'max-loaded', `Billing${capitalizeFirstLetter(watchProductType)}`,
                                              'numeric',getValues().maxLoadedExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              />                                                                                                    
                            </Box>
                            { openProductFilter && watchProductType === 'article' && <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="priceExpression" label={`${t('Expression')} - ${t('Price')} - U`} 
                                multiline rows={3}
                                  {...register('priceExpressionUnity')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) =>
                                            handleClickOpenExpression(event, 'price-unity', 'BillingArticle', 'numeric',getValues().priceExpressionUnity)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              />                                                                                                    
                            </Box> }
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="customerPointExpression" label={`${t('Expression')} - ${t('Customer point')}`} 
                                multiline rows={2}
                                  {...register('customerPointExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) =>
                                            handleClickOpenExpression(event, 'customer-point', `Billing${capitalizeFirstLetter(watchProductType)}`, 
                                              'numeric',getValues().customerPointExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              />                                                                                                    
                            </Box> 
                            { watchProductType === 'service' && <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="serviceBeneficiaryPointExpression" label={`${t('Expression')} - ${t('Service beneficiary point')}`} 
                                multiline rows={2}
                                  {...register('serviceBeneficiaryPointExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) =>
                                            handleClickOpenExpression(event, 'service-point', 'BillingService', 'numeric',getValues().serviceBeneficiaryPointExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              />                                                                                                    
                            </Box> }
                            { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                okText={t('OK')} cancelText='' title={`${t('Expression')} ...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                <EntityExpression entityName={currentEntityName} properties={[]} 
                                  expression={currentExpression} setExpression={setCurrentExpression} />
                              </FormDialog>  }
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>      
                      { ['service', 'packaging', 'rental'].includes(watchProductType) && <Box sx={{ mt: 1, width: '100%' }} >
                        
                              <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget); }}
                                  sx={{ ml: 1, width: '45%', backgroundColor: getValues().appointmentColor, color: theme.palette.getContrastText(getValues().appointmentColor)}}>
                                  {watchProductType === 'rental'?t('Allocation visualisation color'):t('Appointment visualisation color')}
                              </Button>
                              <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={() => {setAnchorEl(null);}}
                              >
                                <ChromePicker color={getValues().appointmentColor} 
                                      onChange={(newColor: ColorResult) => {setValue('appointmentColor',newColor.hex); }} />
                              </Popover>
                              { (watchProductType !== 'rental') && <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Can be public appointment ?')}`}
                                    control={
                                    <Controller
                                        name={`canBePublicAppointment`}  
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />}
                            
                            { openSharingFilter && <FormDialog open={openSharingFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Sharing')} onCancel={()=> {}} 
                              onClose={()=> {setOpenSharingFilter(false);}} onOk={()=> {setOpenSharingFilter(false);}}  >
                                  <BasicTextFilterForm<ISharing> {...basicFilterSharing } />
                          </FormDialog> }
                        </Box> }                  
                        { watchProductType === 'service' && <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IProduct,IServiceTask,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headServiceTaskCells} rowsPathName='serviceTasks' 
                                title={t('Tasks of service')} rowActionIcon={serviceTaskRowActionIcon}  
                                //onRowSelected={handleRoleEntitySelected}
                                                    
                                refAppend={refAppendServiceTasks as MutableRefObject<(value: Partial<FieldArray<IProduct>> | Partial<FieldArray<IProduct>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateServiceTask as MutableRefObject<(index: number,value: Partial<FieldArray<IProduct>>) => void>}
                                refRemove={refRemoveServiceTask as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddServiceTasks ,icon: AddCircleIcon,  },
                                
                                ]}
                                canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                            />
                        </Box> }
                        { watchProductType === 'packaging' && <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IProduct,IPackagingProduct,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headPackagingProductCells} rowsPathName='packagingProducts' 
                              title={t('Products')} rowActionIcon={packagingProductRowActionIcon}  
                              //onRowSelected={handleRoleEntitySelected}
                                                  
                              refAppend={refAppendPackagingProducts as MutableRefObject<(value: Partial<FieldArray<IProduct>> | Partial<FieldArray<IProduct>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdatePackagingProduct as MutableRefObject<(index: number,value: Partial<FieldArray<IProduct>>) => void>}
                              refRemove={refRemovePackagingProduct as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddPackagingProducts ,icon: AddCircleIcon,  },
                              
                              ]}
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          />
                        </Box> }
                        { watchProductType === 'article' && <Box sx={{ mt: 1, width: '100%' }} >
                          <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                    label={`${t('Can sell without stock ?')}`}
                                    control={
                                    <Controller
                                        name='canSellWithoutStock'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                          <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                              label={`${t('Allow customer del. without stock ?')}`}
                              control={
                              <Controller
                                  name='allowBillingDeliveryWithoutStock'
                                  control={control}
                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                          />} />
                          <Controller                                
                            render={({ field }) => {
                              return (
                                <NumberFormat      
                                  //decimalScale={2}
                                  label={t('Reorder Threshold')} sx={{width:'calc(20% - 8px)'}}
                                  allowEmptyFormatting={false}
                                  control={control}

                                  thousandSeparator={true}
                                  {...field}
                                  customInput={TextFieldRight}
                                  //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                />
                              );
                            }}
                            name={`reorderThreshold`}
                            control={control}
                          />
                        </Box>  }
                        { watchProductType === 'consumption' && <Box sx={{ mt: 1, width: '100%' }} >
                          <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Is available ?')}`}
                                    control={
                                    <Controller
                                        name='isConsumptionAvailable'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                          
                        </Box>  }
                        { ['article','rental', 'consumption'].includes(watchProductType) &&  <Box sx={{ mt: 5, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                          <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                            accept="image/*" />
                          <div key={'step.id'}>
                            <Box
                              component="img"
                              sx={carouselImage}
                              //src={`data:image/png;base64,${watchBase64Picture}`}
                              src={isFalsy(watchBase64Picture)?`/images/product.jpg`:`data:image/png;base64,${watchBase64Picture}`}
                              onClick={openFileDialog}
                              //alt={step.id}
                            ></Box>
                          </div>                              
                        </Box>}
                        { watchProductType === 'article' && <Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<IArticleStock> rows={articleStocks} 
                            headCells={[            
                              //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                              {id:'storeName', label : t('_Store'),  display: true, type: 'string', width: 33},
                              {id:'option', label : t('Option'),  display: true, type: 'string', width: 12},
                              
                              {id:'quantityDelivery', label : `${t('_Deli')}(+)`,  display: true, type: 'numeric', width: 10},   
                              {id:'quantityBilling', label : `${t('_Bil')}(-)`,  display: true, type: 'numeric', width: 9},     
                              {id:'quantityTransfertVariation', label : `${t('_Trsf')}`,  display: true, type: 'numeric', width: 9},  
                              {id:'quantityMovementVariation', label : `${t('_Mvt')}`,  display: true, type: 'numeric', width: 8}, 
                                           
                              {id:'quantityInventoryVariation', label : `${t('_Inv')}`,  display: true, type: 'numeric', width: 9},                  
                              {id:'quantity', label : t('Qty'),  display: true, type: 'numeric', width: 10}, 
                            ]} 
                            title={`${t(`Quantities per store`)} - ${t('Total')} : ${sum(articleStocks.map(x => x.quantity))}`} objKey='storeName' 
                            stateSelected={undefined} 
                            onRowSelected={undefined} rowCheckedMode='single'
                            onRowCheckedSelectChange={undefined} order='asc' orderBy='storeName'
                            onRowDoubleClick={undefined} 
                            rowActionIcon={undefined}
                            toolbarActions={[
                              // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                            ]}
                          />
                        </Box>
                        }
                        { watchProductType === 'formation' && <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IProduct,IFormationBillingType,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headFormationBillingTypeCells} rowsPathName='formationBillingTypes' 
                                title={t('Payment schedule')} rowActionIcon={formationBillingTypeRowActionIcon}  
                                //onRowSelected={handleRoleEntitySelected}
                                                    
                                refAppend={refAppendFormationBillingTypes as MutableRefObject<(value: Partial<FieldArray<IProduct>> | Partial<FieldArray<IProduct>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateFormationBillingType as MutableRefObject<(index: number,value: Partial<FieldArray<IProduct>>) => void>}
                                refRemove={refRemoveFormationBillingType as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddFormationBillingTypes ,icon: AddCircleIcon,  },
                                
                                ]}
                                canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                            />
                        </Box> }
                        { watchProductType === 'rental' && <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller name='rentalPeriodicity' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="rentalPeriodicity"
                                    label={t('Periodicity')} inputProps={ {readOnly: false}} focused >
                                    {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_RENTAL_PERIODICITY ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                            />
                        </Box>}
                        { watchProductType === 'rental' && <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IProduct,IRentalResource,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headRentalResourceCells} rowsPathName='rentalResources' 
                                title={t('Allocated resources')} rowActionIcon={rentalResourceRowActionIcon}  
                                //onRowSelected={handleRoleEntitySelected}
                                                    
                                refAppend={refAppendRentalResources as MutableRefObject<(value: Partial<FieldArray<IProduct>> | Partial<FieldArray<IProduct>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateRentalResource as MutableRefObject<(index: number,value: Partial<FieldArray<IProduct>>) => void>}
                                refRemove={refRemoveRentalResource as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddRentalResources ,icon: AddCircleIcon,  },
                                
                                ]}
                                canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                            />
                        </Box> }
                        
                        { openResourceFilter && <FormDialog open={openResourceFilter} maxWidth='md' 
                                  okText='' cancelText='' title={t('Resource')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenResourceFilter(false);}} onOk={()=> {setOpenResourceFilter(false);}}  >
                                      <BasicTextFilterForm<IResource> {...basicFilterResource } />
                              </FormDialog> }
                        { openProductFilter && <FormDialog open={openProductFilter} maxWidth='md'
                                okText={t('OK')} cancelText={t('Cancel')} title={t('Product filter')} onCancel={()=> {setOpenProductFilter(false);}} 
                                onClose={()=> {setOpenProductFilter(false);}} onOk={()=> {setOpenProductFilter(false);}}  >
                                    <BasicTextFilterForm<IProduct> {...basicFilterPackagingProduct } />
                              </FormDialog> }
                        { openBasicImportForm && <FormDialog open={openBasicImportForm} maxWidth='sm' height='45vh'
                                okText={t('Save')} cancelText={t('Cancel')} title={`${t('Import')} ....`} onCancel={()=> {setOpenBasicImportForm(false);}} 
                                onClose={()=> {setOpenBasicImportForm(false);}} onOk={handleBasicImportSave}  >
                                    <BasicImportForm<IProduct> 
                                      stateItems={[productsBasicImport, setProductsBasicImport]} 
                                      columns={['name','description', 'code', 'lineOfBusinessCode', 
                                        'priceExpression', 'priceExpressionUnity']}
                                      defaultItem={defaultProduct} />
                              </FormDialog> }
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

